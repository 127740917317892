<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div class="tw-flex tw-justify-between">
      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-4">{{ titleMapping[$route.name] }}</h1>
      <router-link :to="{ name: 'vendors.dashboard' }" class="tw-text-red tw-text-sm tw-flex tw-items-center tw-mb-4">
        <svg class="tw-mr-3" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 1L1 5L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Back to dashboard
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  computed: mapGetters({
    user: 'auth/user',
  }),
  data() {
    return {
      titleMapping: {
        'vendors.myCompany': 'My company',
        'vendors.showcaseWork': 'Showcase Work',
        'vendors.calendar': 'Calendar',
        'vendors.calendar.settings': 'Calendar settings',
        'vendor.quotes': 'Quotes',
        'vendor.bookings': 'Bookings',
        'vendors.ratings': 'Ratings',
        'vendor.payment-methods': 'Payment methods',
        'vendors.packages': 'Packages',
        'vendors.settings': 'Settings',
      }
    }
  }
}
</script>
